body {
    overflow-x: hidden;
    background: #16181D !important;
    color: #a6a6a6 !important;
}
a{
    color: hsl(27, 100%, 51%) !important;
}
a:hover{
    color: #ffffff !important;
    text-decoration: none !important;
}
.input-group > .input-group-prepend > .btn, .input-group > .input-group-prepend > .input-group-text, .input-group > .input-group-append:not(:last-child) > .btn, .input-group > .input-group-append:not(:last-child) > .input-group-text, .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle), .input-group > .input-group-append:last-child > .input-group-text:not(:last-child){
    background-color: #16181D !important;
    color: #a6a6a6 !important;
    border-radius: 0 !important;
}
#sidebar-wrapper{
    display: flex;
}
.page-link{
    background-color: hsl(27, 100%, 51%) !important;
    color: #ffffff !important;
    border-color: hsl(27, 100%, 51%) !important;
}
.page-item.disabled .page-link{
    background-color: #16181D !important;
    border-color: #444851 !important;
}
.dropdown-menu{
    background-color: #090A0C !important;
}
.dropdown-item:hover, .dropdown-item:focus{
    background-color: #16181D !important;
    color: #fff !important;
    text-decoration: none !important;
}
#page-content-wrapper .row{
    width: 100%;
    margin-left: 0;
    margin-right: 0;
}
th.sortable{
    color: hsl(27, 100%, 51%) !important;
}
.table {
    color: #a6a6a6 !important;
}
.table-bordered th, .table-bordered td {
    border: 1px solid #444851 !important;
}
.modal-footer{
    border-color: #444851 !important;
}
.navAdmin{
    background-color: #090A0C;
    border-bottom: 1px solid #ffbb03;
}
.authBox{
    background: rgba(0,0,0,.7) !important;
    border: 2px solid hsl(27, 100%, 51%) !important;
    color: #fff !important;
}
.authBox label{
    color: #fff;
}
.authBox a{
    color:hsl(27, 100%, 51%) !important;
    transition: .5s;
}
.authBox a:hover{
    color: #ffffff !important;
    text-decoration: none;
}
.authBox input[type=text],
.authBox input[type=email],
.authBox input[type=number],
.authBox input[type=password]{
    padding: 10px 15px;
    box-shadow: none !important;
    background-color: #e8e8e8;
    border: 2px solid #e8e8e8;
}
.authBox input[type=text]:focus,
.authBox input[type=email]:focus,
.authBox input[type=number]:focus,
.authBox input[type=password]:focus{
    border: 2px solid #ffba00;
}
.as-react-table input[type=text],
.as-react-table input[type=email],
.as-react-table input[type=number],
.as-react-table input[type=search],
.as-react-table input[type=password]{
    padding: 10px 15px;
    box-shadow: none !important;
    background-color: #e8e8e8;
    border: 2px solid #e8e8e8;
}
.as-react-table input[type=text]:focus,
.as-react-table input[type=email]:focus,
.as-react-table input[type=number]:focus,
.as-react-table input[type=search]:focus,
.as-react-table input[type=password]:focus{
    border: 2px solid #ffba00;
}
#sidebar-wrapper {
    min-height: 100vh;
    margin-left: -15rem;
    -webkit-transition: margin .25s ease-out;
    -moz-transition: margin .25s ease-out;
    -o-transition: margin .25s ease-out;
    transition: margin .25s ease-out;
    background-color: #090A0C;

}
#sidebar-wrapper.border-right{
    border:0 !important;
}
.btnTypeadmin1 {
    background: hsl(27, 100%, 51%) !important;
    border: 1px solid hsl(27, 100%, 51%) !important;
    color: #fff !important;
    font-size: 18px;
    font-weight: 600;
    padding: 10px 30px !important;
    border-radius: 50px;
    margin-bottom: 10px;
    -webkit-transition: .5s;
    transition: .5s;
    box-shadow: none !important;
}
.btnTypeadmin1:hover {
    background: transparent !important;
    color: #ffba00 !important;
}

.btnTypeadmin2{
    background: #4C4E51 !important;
    border: 1px solid #4C4E51 !important;
    color: #fff !important;
    font-size: 18px;
    font-weight: 600;
    padding: 10px 30px !important;
    border-radius: 50px;
    margin-bottom: 10px;
    -webkit-transition: .5s;
    transition: .5s;
    box-shadow: none !important;
}
.btnTypeadmin2:hover {
    background: transparent !important;
    color: #a6a6a6 !important;
}
.modal-content .btn{
    padding: 0.375rem 0.75rem !important;
    margin-bottom: 0 !important;
}



.btn-primary {
    background: hsl(27, 100%, 51%) !important;
    border: 1px solid #ffba00 !important;
    color: #fff !important;
    font-size: 18px;
    font-weight: 600;
    padding: 3px 7px !important;
    border-radius: 50px;
    margin-bottom: 10px;
    -webkit-transition: .5s;
    transition: .5s;
    box-shadow: none !important;
}
.btn-primary:hover {
    background: transparent !important;
    color: hsl(27, 100%, 51%) !important;
}

.btn-danger {
    background: #4C4E51 !important;
    border: 1px solid #4C4E51 !important;
    color: #fff !important;
    font-size: 18px;
    font-weight: 600;
    padding: 3px 7px !important;
    border-radius: 50px;
    margin-bottom: 10px;
    -webkit-transition: .5s;
    transition: .5s;
    box-shadow: none !important;
}
.btn-danger:hover {
    background: transparent !important;
    color: #a6a6a6 !important;
}


#sidebar-wrapper .sidebar-heading {
    padding: 0.875rem 1.25rem;
    font-size: 1.2rem;
}

#sidebar-wrapper .list-group {
    width: 15rem;
}

#page-content-wrapper {
    min-width: 100vw;
}

#wrapper.toggled #sidebar-wrapper {
    margin-left: 0;
}

@media (min-width: 768px) {
    #sidebar-wrapper {
        margin-left: 0;
    }

    #page-content-wrapper {
        min-width: 0;
        width: 100%;
        margin-top: 1%;
    }

    #wrapper.toggled #sidebar-wrapper {
        margin-left: -15rem;
    }
}

#as-react-datatable-container select {
    width: 70px !important;
}

.container input {
     position: static !important; 
     opacity: 1 !important; 
     cursor: pointer !important; 
     height: auto !important; 
     width: 100% !important; 
}

.container [type=checkbox] {
    position: absolute !important;
    opacity: 0 !important;
    cursor: pointer !important;
    height: 0 !important;
    width: 0 !important;
}

.chip-body{
    height: 37px !important
}
.multiSelect{
    margin :0 !important;
}

.tableHead h2 {
    font-size: 24px;
    color: #d6b032;
    text-align: left;
    margin: 12px 15px 15px;
    font-weight: 600;
}

@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(https://example.com/MaterialIcons-Regular.eot); /* For IE6-8 */
    src: local('Material Icons'),
      local('MaterialIcons-Regular'),
      url(https://example.com/MaterialIcons-Regular.woff2) format('woff2'),
      url(https://example.com/MaterialIcons-Regular.woff) format('woff'),
      url(https://example.com/MaterialIcons-Regular.ttf) format('truetype');
  }

  .material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;  /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    /* color: #b3120d; */
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
  
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
  
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
  
    /* Support for IE. */
    font-feature-settings: 'liga';
  }


  .list-group-flush .list-group-item {
    border-right: 0;
    border-left: 0;
    border-radius: 0;
    background-color: #090A0C;
    color: #FFBA00;
    display: flex;
    align-items: center;
}
 .list-group-flush .list-group-item:hover, .list-group-item:focus {
    background-color: hsl(27, 100%, 51%) !important;
    color: #ffffff !important;
 }
.list-group-flush .list-group-item i {
    margin-right: 10px;
    width: 25px;
    text-align: center;
}
h3.mt-2.text-secondary {
    color: #fff !important;
}

nav.navbar.navbar-expand-lg.navbar-dark.bg-dark {
    background-color: #122336 !important;
}

a#settings {
    color: white;
}

button.btn.btn-outline-primary.float-right.mt-3.mr-2 {
    color: #072338;
    border-color: #072338;
}


.hidden { display:none; }
.dropdown-menu.notification_dropdown.show {
    width: 270px;
    height: 350px;
    overflow-y: scroll;
}
ul.notificationList {
    padding: 10px;
    
}
ul.notificationList li h4,
ul.notificationList li h4 small {
    color: #fff;
    font-size: 15px;
}
ul.notificationList li p {
    font-size: 13px;
    color: #fff;
    margin: 0;
}
.notificationList li {
    border-bottom: 1px solid rgb(255 255 255 / 20%);
    padding: 10px 0;
}
